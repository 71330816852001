import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Axios from 'axios';

import Slider from '../slider';
import difmLogo from '../../images/logo.png';
import ico_all from '../../images/icon_all.png';
import sc_1 from '../../images/Robotic_Mower.png';
import sc_2 from '../../images/Garden_Sheds.png';
import sc_3 from '../../images/kitchen.png';
// import sc_4 from '../../images/Home_Security.png';
import sc_4 from '../../images/Fire_Place.png';
import MapMarker from '../../images/Map-Marker.png';
import mower from '../../images/mower.png'

import flg_en_BE from '../../images/Flag_of_England.svg';
import flg_nl_BE from '../../images/Flag_of_Netherlands.svg';
import flg_fr_BE from '../../images/Flag_of_France.svg';
import flg_de_DE from '../../images/Flag_of_Germany.svg';


import { Manager, Reference, Popper } from 'react-popper';
import { Link, navigate } from 'gatsby';

import { FaExclamationTriangle } from "react-icons/fa";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/src/custom-style.css';

const internals = {
    services: {
        '1': 'New Installation',
        '2': 'Service Visit',
        '3': 'Wintercare at Home',
        '4': 'Wintercare at DIFM',
        '5': 'Winterstorage and wintercare at DIFM',
        '6': 'Winterstorage at DIFM',
        '7': 'New Installation',
        '8': 'Commissioning',
        '9': 'New Installation',
        '10': 'Maintenance',
        '11': "Repair",
        '12': "Warranty",
        '13': "New Request",
        "14": "Warranty",
        '15': 'Redirect'
    }
};

internals.serviceCategories = [
    { id: 'robotic mowers', img: sc_1, name: 'Robotic Mower', active: true },
    { id: 'garden sheds', img: sc_2, name: 'Garden Sheds', active: true },
    { id: 'kitchen', img: sc_3, name: 'Kitchen', active: true },
    { id: 'fire places', img: sc_4, name: 'Fire Place', active: true },
];

internals.getImage = (lang) => {

    switch (lang.toLowerCase()) {
        case 'nl_be':
            return flg_nl_BE;
        case 'fr_be':
            return flg_fr_BE;
        case 'de_de':
            return flg_de_DE;
        default:
            return flg_en_BE;
    }
}

class Search extends Component {

    state = {
        searchResults: {
            category: [],
            brand: [],
            service: [],
            data: []
        },
        categoryBrands: [],
        brandModels: [],
        selectedBrand: '',
        selectedCategory: '',
        selectedService: '',
        channel: this.props.channel || 'default',
        country: this.props.country || 'be',
        flipped: {},
        countrySelection: false,
        languageSelection: false,
        serviceSelection: false,
        validate: '',
        open: false,
        modalContent: null
    }

    refSearchInput = React.createRef();

    search = async () => {

        const q = this.refSearchInput.current.value;
        let url = `/api/search?country=${this.props.country}&channel=${this.props.channel}&page=1&limit=999`;
        // let url = `/api/search?country=${this.props.country}&channel=${this.props.channel}`;
        if (q) {
            url += `&q=${q}`;
        }

        ['selectedBrand', 'selectedCategory', 'selectedService'].forEach((type) => {

            if (this.state[type]) {
                const param = type.replace('selected', '').toLowerCase();
                let stateType = this.state[type] === 'black & decker' ? 'black + decker' : this.state[type] ; 
                url += `&${param === 'service' ? 'service_type' : param}=${stateType}`;
            }
            // console.log(url); 
        });
        const { data } = await Axios.get(url);
        // console.log(data); 
        return data;
    }

    lowestPrice = (item, service, t) => {
        return t('flip for more info'); 
        let { selectedService } = this.state;
        let price = 1e5;
        service = service || item.services[0]; 
        if(item.channel === 'hubo_powerplus' && selectedService === ''){
            // console.log('hubo_powerplus'); 
            selectedService = '1'; 
        }
        for (let i = 0; i < item.services.length; ++i) {
            const currService = item.services[i];
             if (selectedService === '') {
                if (price > currService.price) {
                    service = currService;
                    price = currService.price;
                }
            }
            else if (currService.service_type === selectedService) {
                service = currService;
                price = currService.price;
                break;
            }
        }
        // console.log("lowestPrice: " + service.isFree); 
        if (service.isFree === 3) {
            return t('Already Paid');
        }
        if (service.isFree === 4) {
            return t('Quotation');
        }
        if (price === 0) {
            return item.model.toLowerCase() === 'default' ? t('Quotation') : t('Free');
        }
        if (price === 1e5) {
            return '';
        }

        const currency = item.currency;
        const formatter = new Intl.NumberFormat(this.language, { style: 'currency', currency: currency || 'EUR' });
        return formatter.format(price);
    }

    formatCurrency = (item, service, t) => {
        // console.log(item); 
        // console.log(service); 

        const currency = item.currency;
        const formatter = new Intl.NumberFormat(this.language, { style: 'currency', currency: currency || 'EUR' });
        service = service || item.services[0];
        // console.log(service); 
        const num = service ? service.price : item.price;

        if(service.isFree === 6 || service.isFree === 'redirect'){
            return this.sidenoteModal('redirect', service.sidenote, t);
        }

        if (num === 0) {
            if (service.isFree === 3 || service.isFree === 'prepaid') {
                return this.sidenoteModal(t('Already Paid'), service.sidenote, t);
            }
            if (service.isFree === 4 || service.isFree === 'quotation') {
                return this.sidenoteModal(t('Quotation'), service.sidenote, t);
            }
            return this.sidenoteModal(item.model.toLowerCase() === 'default' ? t('Quotation') : t('Free'), service.sidenote, t);
        } else if(service.isFree === 4 || service.isFree === 'quotation'){
            return this.sidenoteModal(t('Quotation') + " (" + t("starts at") + " " + formatter.format(num) + "*)", service.sidenote, t);
        } else if(service.isFree === 5 || service.isFree === 'deposit'){
            return this.sidenoteModal(t("min.") + " " + formatter.format(num), service.sidenote, t);
        }

        return this.sidenoteModal(formatter.format(num), service.sidenote, t);
    }

    sidenoteModal = (format, sidenote, t) => {
        const { open, modalContent } = this.state;
        // console.log(sidenote); 
        if(format === "redirect"){
            return (<a href={sidenote} target="_blank">{t(format)}</a>); 
        }
        if(sidenote){
            const content = t(sidenote);
            return (
                <div>
                    {format} <button type="button" class="sidenoteBtn" onClick={(e) => this.openModal(content)} ><FaExclamationTriangle /></button>
                    <Modal
                        open={open} 
                        onClose={(e) => this.closeModal()} 
                        center
                        classNames={{
                            overlay: 'customOverlay',
                            closeButton: 'customCloseButton',
                          }}
                    >
                        <p 
                            style={{margin: '1.5em'}}
                            dangerouslySetInnerHTML={{__html: modalContent}}    
                        />
                    </Modal>
                </div>
              );
        } else {
            return (<div>{format}</div>); 
        }
    }

    flip = (e, item) => {


        const flipped = this.state.flipped;
        let status = true;
        if (flipped.hasOwnProperty(item)) {
            status = !flipped[item];
        }
        const newFlipped = Object.assign({}, this.state.flipped);
        newFlipped[item] = status;
        this.setState({ flipped: newFlipped });
    }

    handleSelectCategory = async (e, selectedCategory) => {

        this.setState({ selectedCategory }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            // results.brand = this.state.searchResults.brand;
            // results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} });
        });
    }

    handleServicesClick = (e, selectedCategory, active) => {
        if (active) {
            this.setState({
                searchModalOpen: true,
                selectedCategory
            });
        }

    }


    handleSubmit = async (e, item) => {
        e.preventDefault();
        const { t } = this.props;

        let selected_service; 
        
        const hiddenEl = e.target.querySelectorAll('[type="hidden"]'); 
        for (let i = 0; i < hiddenEl.length; ++i) {
            selected_service = hiddenEl[i].value;
        }

        if(!hiddenEl.length){
            const serviceEl = e.target.getElementsByTagName('input');
            
            for (let i = 0; i < serviceEl.length; ++i) {
                if (serviceEl[i].checked) {
                    selected_service = serviceEl[i].value;
                }
            }
        }
        // console.log(selected_service); 
        let price = item.price;
        let rid = item.rid;

        // let isFree = false;
        let isFree = item.isFree;
        let sidenote = item.sidenote; 
        if (selected_service) {
            for (let i = 0; i < item.services.length; ++i) {
                const service = item.services[i];
                if (service.service_type === selected_service) {
                    price = service.price;
                    rid = service.rid;
                    isFree = service.isFree;
                    sidenote = service.sidenote; 
                    break;
                }
            }
            if(selected_service == 15){ //service_type::15 -> redirect for robotic mowers 
                window.location.href = sidenote; 
                return false; 
            }

            const { data } = await Axios({
                url: '/api/survey/init',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    country: this.props.country,
                    language: this.props.language === 'de_DE' ? 'de' : this.props.language, //why?
                    category: item.category,
                    brand: t(item.brand),
                    model: t(item.model),
                    capacity: t(item.capacity),
                    rid: rid,
                    channel: t(item.channel),
                    service_type: selected_service,
                    isFree: isFree,
                    price: price,
                    currency: item.currency,
                    area: item.area
                }
            });
            // console.log(data); 
            const uid = data.uid;
            const channel_path = `${(this.props.channel === 'default' ? '' : '/' + this.props.channel).toLowerCase()}`;
            const category = data.category; 
            
            window.location.href = `/${this.props.language.toLowerCase()}/${this.props.country}${channel_path}/quote/?uid=${uid}`; 
            // navigate(`/${this.props.language.toLowerCase()}/${this.props.country}${channel_path}/quote/?uid=${uid}`, { state: { uid: uid }, replace: true});
        }
        else {
            this.setState({ validate: item.sku });
            return false;
        }
    }

    handleSelectBrand = async (e, selectedBrand, i) => {
        this.setState({ selectedBrand }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            results.brand = this.state.searchResults.brand;
            results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} });
        })
    }

    handleSelectService = async (e, selectedService) => {

        this.setState({ selectedService }, async () => {

            const results = await this.search();
            results.category = this.state.searchResults.category;
            results.brand = this.state.searchResults.brand;
            results.service = this.state.searchResults.service;
            this.setState({ searchResults: results, flipped: {} });
        })
    }

    handleSearchChange = async (e) => {

        const run = async () => {
            const results = await this.search();
            this.setState({ searchResults: results });
        }

        const q = this.refSearchInput.current.value;

        if (!q) {
            return this.setState({
                searchResults: {
                    category: [],
                    brand: [],
                    service: [],
                    data: []
                }, selectedBrand: ''
            }, async () => {
                await run();
            });
        }
        await run();
    }

    componentDidMount() {

        const qs  = this.props.location.search.toLowerCase().slice(1).split('&');
        let validFilter = false;
        let filterType = null;  
        let selections = { flipped: {} };
        for (let i = 0; i < qs.length; ++i) {
            const [key, val] = qs[i].split('=');

            switch(key) {
                case 'category':
                    validFilter = true;
                    filterType = 'category'; 
                    selections['selectedCategory'] = val.replace('_', ' ');
                    break;
                case 'service':
                    validFilter = true;
                    filterType = 'service'; 
                    selections['selectedService'] = val;
                    break;
                default:
                    validFilter = false;
                    filterType = null; 
                    break;
            }
        }

        const run = async () => {

            const results = await this.search();
            this.setState({ searchResults: results });
            if (validFilter && filterType === 'category') {
                this.handleSelectCategory(null, selections.selectedCategory);
            }    
            if (validFilter && filterType === 'service') {
                this.handleSelectService(null, selections.selectedService);
            }    
        }

        run();

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {

        if (event.target.id !== 'selectedCity' && event.target.className !== 'city-result' && event.target.className !== 'city-selection') {
            this.setState({ countrySelection: false });
        }

        if (event.target.id !== 'selectedLanguage') {
            this.setState({ languageSelection: false });
        }
    }

    startCountrySelection = (e) => {

        this.setState({
            countrySelection: !this.state.countrySelection, searchResults: {
                category: [],
                brand: [],
                service: [],
                data: [],
            }, serviceSelection: false, flipped: {}
        });
    }

    startLanguageSelection = () => {
        this.setState({ languageSelection: !this.state.languageSelection });
    }

    renderBrandBar(brand, i, selectedBrand, t) {
        if(brand.key === 'powerplus' && 1 === 2){ /* deprecated */
            return ([
                <div
                    key={brand.key}
                    className={`facet-brand ${brand.key === selectedBrand ? 'active' : ''} watch-title`}
                    onClick={(e) => this.handleSelectBrand(e, brand.key, i + 1)}
                >
                <img
                    src={`/api/images/brands/hubo.png`}
                    alt={`hubo`}
                    style={{
                        flex: '0 0 auto',  /* IE */
                        objectFit: 'scale-down' /* FF */
                    }}
                />
            </div>,
            <div
                key={brand.key}
                className={`facet-brand ${brand.key === selectedBrand ? 'active' : ''} watch-title`}
                onClick={(e) => this.handleSelectBrand(e, brand.key, i + 1)}
            >
                <img
                    src={`/api/images/brands/${brand.key.toLowerCase().replace(/[\s/]+/ig, '-')}.png`}
                    alt={t(brand.key)}
                    style={{
                        height: '33.14px',
                        flex: '0 0 auto',  /* IE */
                        objectFit: 'scale-down' /* FF */
                    }}
                />
            </div>
            ]); 
        } else if(brand.key === 'blank') {
            return ([]); 
        } else {
            return (
                <div
                    key={brand.key}
                    className={`facet-brand ${brand.key === selectedBrand ? 'active' : ''} watch-title`}
                    onClick={(e) => this.handleSelectBrand(e, brand.key, i + 1)}
                >
                    <img
                        src={`/api/images/brands/${brand.key.toLowerCase().replace(/[\s/]+/ig, '-')}.png`}
                        alt={t(brand.key)}
                        style={{
                            flex: '0 0 auto',  /* IE */
                            objectFit: 'scale-down' /* FF */
                        }}
                    />
                </div>
            );
        }
    }

      // Function to open a modal and set the content based on the button click
    openModal = (contentComponent) => {
        this.setState({
            open: true,
            modalContent: contentComponent,
        });
    };

    // Function to close the modal
    closeModal = () => {
        this.setState({
            open: false,
            modalContent: null,
        });
    };

    render() {

        const self = this;

        const { countries, t, language } = this.props;

        const countryOpacity = this.state.countrySelection ? 1 : 0;
        const opacity = this.state.languageSelection ? 1 : 0;
        const visible = true;
        const channel = this.props.channel;
        const channel_path = `${(channel === 'default' ? '' : '/' + channel).toLowerCase()}`;

        return (
            <div className="search-container">
                <header id="header">
                    <div className="container header">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-4 col-lg-3 d-flex align-items-center m-w-50 logo-box">
                                <Link to="/">
                                    <img src={difmLogo} style={{ height: '60px', margin: '0' }} alt="Do It For Me" />
                                </Link>
                            </div>
                            <div className="col-8 col-lg-9 header-nav" style={{ height: '60px' }}>
                                <ul>
                                    <li>
                                        <Manager>
                                            <Reference>
                                                {({ ref }) => (
                                                    <div style={{ position: 'relative', height: '60px', width: '200px' }} className="" ref={ref} onClick={self.startCountrySelection}>
                                                        <div className="Countryselect" style={{ marginTop: '20px' }}>
                                                            <img alt="select country" src={MapMarker} /> {t(this.state.country)}
                                                            <a href="#change-country">change</a>
                                                        </div>
                                                        {/* <i className="la la-map-marker"></i>
                                                        <span className="current-location">{t(this.state.country)}</span>
                                                    <i className="la la-angle-down"></i> */}
                                                    </div>
                                                )}
                                            </Reference>
                                            <Popper placement="bottom-end" >
                                                {({ ref, style, placement }) => (
                                                    <div className="city-selection search-popup" ref={ref} style={{
                                                        ...style,
                                                        opacity: countryOpacity,
                                                        pointerEvents: countryOpacity ? 'auto' : 'none'
                                                    }} data-placement={placement}>
                                                        {
                                                            countries.map((item) => (
                                                                <Link
                                                                    to={`/${self.props.language.toLowerCase()}/${item.code.toLowerCase()}${channel_path}/search`}
                                                                    key={item.code}
                                                                >
                                                                    <div
                                                                        className="city-result"
                                                                        key={item.code}
                                                                    >
                                                                        {t(item.code)}
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        }
                                                    </div>
                                                )}
                                            </Popper>
                                        </Manager>
                                    </li>
                                    <li>
                                        <Manager>
                                            <Reference>
                                                {({ ref }) => (
                                                    <div
                                                        className="selected-language d-flex align-items-center"
                                                        ref={ref}
                                                        id="selectedLanguage"
                                                        onClick={(e) => { self.startLanguageSelection(e) }}
                                                    >
                                                        <img alt={t(language)} src={internals.getImage(language)} />
                                                        {t(language)}
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                )}
                                            </Reference>
                                            <Popper>
                                                {({ ref, style, placement }) => (
                                                    <div className="language-selector" ref={ref} style={{ ...style, opacity }} data-placement={placement}>
                                                        {
                                                            Config.languages.map((lang) => {
                                                                let el = null;
                                                                if (lang.code !== language) {
                                                                    el = (
                                                                        <div key={lang.code} onClick={(e) => { e.preventDefault(); navigate(`/${lang.code.toLowerCase()}/${self.props.country}/${channel_path}/search`) }}>
                                                                            <img width="20" alt={t(lang.code)} src={internals.getImage(lang.code)} />
                                                                            {t(lang.code)}
                                                                        </div>
                                                                    );
                                                                }
                                                                return el;
                                                            })
                                                        }
                                                    </div>
                                                )}
                                            </Popper>
                                        </Manager>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <div className="row">
                        <div className="col-12 search-input">
                            <input
                                ref={self.refSearchInput}
                                placeholder={t('Search...')}
                                onChange={this.handleSearchChange}
                            />
                        </div>
                    </div>
                    {
                        visible &&
                        <div className="row mt-3 after-search">
                            <div className="col-12 p-r-0">
                                <div className="d-flex justify-content-start align-items-center">
                                    <Slider numDesktopItems={6} numMobileItems={2}>
                                        <div
                                            className={`facet-category ${this.state.selectedCategory ? '' : 'active'}`}
                                            onClick={(e) => this.handleSelectCategory(e, '')}
                                        >
                                            <img src={ico_all} alt={t('All Categories')} />
                                            <div className="name">{t('All Categories')}</div>
                                        </div>
                                        {
                                            internals.serviceCategories.map((category) => {

                                                let status = ''; //previous 'inactive'
                                                for (let i = 0; i < this.state.searchResults.category.length; ++i) {
                                                    if (this.state.searchResults.category[i].key === category.id) {
                                                        status = category.id === this.state.selectedCategory ? 'active' : '';
                                                    }
                                                }

                                                return (
                                                    <div
                                                        key={category.id}
                                                        className={`facet-category ${status} d-flex flex-column align-items-center justify-content-center`}
                                                        onClick={(e) => this.handleSelectCategory(e, category.id)}
                                                    >
                                                        <img alt={category.name} src={category.img} />
                                                        <div id={category.id} className="name">{t(category.name)}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        visible &&
                        <div className="row mt-4 mb-4 brand-logo-bar">
                            <div className="col-12 p-r-0">
                                <div className="d-flex  flex-nowrap lomo thumbs">
                                    <Slider numDesktopItems={6} numMobileItems={2}>
                                        <div
                                            className={`facet-brand ${this.state.selectedBrand === '' ? 'active' : ''}`}
                                            onClick={(e) => this.handleSelectBrand(e, '')}
                                        >
                                            <div>{t('All Brands')}</div>
                                        </div>
                                        {
                                            this.state.searchResults.brand.map((brand, i) => (
                                                this.renderBrandBar(brand, i, this.state.selectedBrand, t)
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        visible &&
                        <div className="row mt-4 mb-4 m-b-12">
                            <div className="col-12">
                                <div className="justify-content-end d-webkit">
                                    <div
                                        className={`facet-service ${this.state.selectedService === '' ? 'active' : ''}`}
                                        onClick={(e) => this.handleSelectService(e, '')}
                                    >
                                        {t('All Services')}
                                    </div>
                                    {
                                        this.state.searchResults.service.map((service) => (
                                            <div
                                                key={service.key}
                                                className={`facet-service ${service.key === this.state.selectedService ? 'active' : ''}`}
                                                onClick={(e) => this.handleSelectService(e, service.key)}
                                            >
                                                <div className="checkmark"></div>
                                                {t(internals.services[service.key])}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {
                            this.state.searchResults.data.map((item) => {
                                // console.log(item); 
                                // const flipped = this.state.flipped.hasOwnProperty(item.sku) ? this.state.flipped[item.sku] : false;
                                return (
                                    <div className="col-3 result p-0" key={item.sku}>

                                        <div
                                            key="front"
                                            className="card main-card"
                                            onMouseEnter={(e) => this.flip(e, item.sku)}
                                        >
                                            <div className="productImg d-flex align-items-center">
                                                <img
                                                    className="card-img-top"
                                                    src={item.image}
                                                    alt={item.model}
                                                    style={{
                                                        flex: '0 0 auto',  /* IE */
                                                        objectFit: 'scale-down' /* FF */
                                                    }}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = mower }}
                                                />
                                            </div>
                                            <div className="card-body">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12" style={{ position: 'relative' }}>
                                                            <div className="brand">
                                                                <img
                                                                    alt={t(item.brand)}
                                                                    src={`/api/images/brands/${item.brand.toLowerCase().replace(/[\s/]+/ig, '-')}.png`}
                                                                    onError={(e) => { e.target.onerror = null; e.target.src = '' }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className="model">{item.model === 'default' ? t('Other Model') : t(item.model)}</div>

                                                                <div className="area mt-1">{item.capacity !== "..." ? t('covers') + ' ' + item.capacity + ' ' + t('sq.m. area') : '.'}</div>
                                                            </div>
                                                            <div className={"price"} style={{fontSize: '0.75em'}}>
                                                                {this.lowestPrice(item, null, t) + '*'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            key="back" className="card overlay"
                                            onMouseLeave={(e) => this.flip(e, item.sku)}
                                        >
                                            <form onSubmitCapture={(e) => { this.handleSubmit(e, item) }}>
                                                <div className="container p-0">
                                                    <div className="row mt-3">
                                                        <div className="col-12 brand">
                                                            {item.brand === 'blank' ? '.' : t(item.brand)}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div title={item.model === 'default' ? t('Other Model') : t(item.model)} className="col-12 model">
                                                            {item.model === 'default' ? t('Other Model') : t(item.model)}
                                                        </div>
                                                        <div className="col-12 area">
                                                            {item.capacity !== '...' ? t('covers') + ' ' + item.capacity + ' ' + t('sq.m. area') : '.'}
                                                        </div>
                                                    </div>
                                                    <small style={{ visibility: `${this.state.validate === item.sku ? 'visible' : 'hidden'}` }}
                                                        className="validation">Please select a service.</small>
                                                    {
                                                        item.services.map((service) => (

                                                            <div className="row align-items-center" key={`${service.id}_service_${service.service_type}`} style={{ visibility: `${service.service_type == 15 ? 'hidden' : 'visible'}` }}>
                                                                <div className="col-6">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type={service.service_type == 15 ? "hidden" : "radio"}
                                                                            disabled={service.isFree === 6 || service.isFree === 'redirect'}
                                                                            name="services"
                                                                            id={`${service.id}_service_${service.service_type}`}
                                                                            value={service.service_type}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`${service.id}_service_${service.service_type}`}>
                                                                            {t(internals.services[service.service_type])}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 service-price">
                                                                    {this.formatCurrency(item, service, t)}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <button type="submit" className="cta">{t('Book Now')}</button>
                                            </form>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div>(*) {t('SEARCHLOWESTPRICETEXT')}</div>
                </div>
            </div>
        );
    }
}

Search.propTypes = {
    t: PropTypes.func
};

export default Search;
